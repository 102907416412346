import React from 'react'
import { useEffect } from 'react'
import { navigate } from 'gatsby'
import SEO from '../../components/seo'

export default () => {
  useEffect(() => {
    navigate('/portfolio/mapfit')
  }, [])
  return (
    <>
      <SEO title="Tabreezi Portfolio" />
      <div
        style={{ backgroundColor: 'black', width: '100vw', height: '100vh' }}
      />
    </>
  )
}
